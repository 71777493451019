import { Navigate } from "react-router-dom";
import Navbar from "../layouts/NavBar";
const Protected = (props) => {
  if (!props.isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return (
    <div>
      <Navbar logOut={props.logOut} userObject={props.userObject} />
      {props.children}
    </div>
  );
};
export default Protected;
