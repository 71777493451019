import React, { useState } from "react";
import { Reorder, steps } from "framer-motion";
import DefaultConfig from "../../DefaultConfig";
import "./CreateWorkFlow.css";

const CreateWorkFlow = (props) => {
  const [task, setTask] = useState("");
  const [workFlowName, setWorkFlowName] = useState("");
  const [workflowNameError, setWorkFlowNameError] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [taskListError, setTaskListError] = useState(false);

  const onTaskChangeHandler = (e) => {
    setTask(e.target.value);
  };

  const setWorkFlowNameHandler = (e) => {
    setWorkFlowName(e.target.value);
  };

  const addToList = () => {
    if (task.trim() !== "") {
      let dupCheck = taskList.find((taskItem) => taskItem === task);
      if (dupCheck) {
        return;
      }
      setTaskList([...taskList, task]);
      setTask("");
      setTaskListError(false);
    }
  };

  const deleteStep = (item) => {
    const newTaskList = taskList.filter((task) => task !== item);
    setTaskList(newTaskList);
  };

  // create workflow handler
  const CreateFlowHandler = (e) => {
    e.preventDefault();
    if (workFlowName.trim() === "" || taskList.length === 0) {
      if (workFlowName.trim() === "") {
        setWorkFlowNameError(true);
        return;
      } else {
        setWorkFlowNameError(false);
      }
      if (taskList.length === 0) {
        setTaskListError(true);
        return;
      } else {
        setTaskListError(false);
      }
    }
    {
      setWorkFlowNameError(false);
      setTaskListError(false);
      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.userObject.userObj.token,
        },
        body: JSON.stringify({
          agencyCode: props.userObject.userObj.agencyCode,
          steps: taskList,
          workFlowName: workFlowName,
        }),
      };
      //
      // posting the create work flow process
      //
      fetch(DefaultConfig + "workflow/addworkflow", requestOptions)
        .then(async (response) => {
          const isJson = response.headers
            .get("content-type")
            ?.includes("application/json");
          const data = isJson && (await response.json());
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            console.log("error", error);
            return Promise.reject(error);
          }
          console.log("Work Flow Created", data);
          alert(data.message);
          setTaskList([]);
          setWorkFlowName("");
        })
        .catch((error) => {
          alert("Not update error " + error);
        });
    }
  };

  return (
    <div className="CreateFlow container">
      <div className="row">
        <h1>Create WorkFlow</h1>
      </div>
      <div className="row">
        <div className="col">Agency Code</div>
        <div className="col">{props.userObject.userObj.agencyCode}</div>
      </div>

      <div className="row">
        <div className="col">
          <input
            type="text"
            placeholder="workflow name"
            required
            onChange={setWorkFlowNameHandler}
            value={workFlowName}
          />
          {workflowNameError && <p className="error">Required field</p>}
        </div>
        <div className="col">
          <input
            type="text"
            placeholder="workflow step"
            required
            onChange={onTaskChangeHandler}
            value={task}
          />
          {taskListError && <p className="error">Required field</p>}
        </div>
        <div className="col">
          <button onClick={addToList}>add</button>
        </div>
      </div>
      <div className="row WorkFlowList">
        {taskList.length === 0 && <p>No task added yet</p>}
        <Reorder.Group axis="y" values={taskList} onReorder={setTaskList}>
          {taskList.map((item) => (
            <Reorder.Item key={item} value={item}>
              <div className="row">
                <div className="col">
                  <div>{item}</div>
                </div>
                <div className="col">
                  <button
                    onClick={() => {
                      deleteStep(item);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </div>
      <div className="row">
        <div>
          <button onClick={CreateFlowHandler}>Create Workflow</button>
        </div>
      </div>
    </div>
  );
};

export default CreateWorkFlow;
