import "./StartWorkFlow.css";
import { useEffect, useState } from "react";
import DefaultConfig from "../../DefaultConfig";

const StartWorkFlow = (props) => {
  const [workFlowMasterList, setWorkFlowMasterList] = useState({
    workFlowMaster: [],
  });
  const [workflowSelected, setWorkflowSelected] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [agencyUsers, setAgencyUsers] = useState({ users: [] });
  const [workFlowTitle, setWorkFlowTitle] = useState("");
  const [workFlowNotes, setWorkFlowNotes] = useState("");

  const selectWorkFlowHandler = (e) => {
    setWorkflowSelected(e.target.value);
  };

  const selectUserHandler = (e) => {
    setSelectedUser(e.target.value);
  };

  useEffect(() => {
    getworkflowsDB(); // get the list of work flows
    getUsersDB();
  }, []);

  // get the workflow masters for a agency
  const getworkflowsDB = () => {
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.userObject.userObj.token,
      },
      body: JSON.stringify({
        agencyCode: props.userObject.userObj.agencyCode,
      }),
    };
    //
    // get all the work flows for an agency
    //
    fetch(DefaultConfig + "workflow/getworkflows", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.log("error", error);
          return Promise.reject(error);
        }
        setWorkflowSelected(data.workFlowMaster[0]._id);
        setWorkFlowMasterList(data);
        console.log(data);
      })
      .catch((error) => {
        alert("Not update error " + error);
      });
  };

  // geh the users for a agency
  const getUsersDB = () => {
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.userObject.userObj.token,
      },
      body: JSON.stringify({
        agencyCode: props.userObject.userObj.agencyCode,
      }),
    };
    //
    // get all the work flows for an agency
    //
    fetch(DefaultConfig + "user/users", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.log("error", error);
          return Promise.reject(error);
        }
        setSelectedUser(data.users[0]._id);
        setAgencyUsers(data);
        console.log(data);
      })
      .catch((error) => {
        alert("Not update error " + error);
      });
  };

  //console.log(workFlowMasterList);

  const workFlowTitleHandler = (e) => {
    setWorkFlowTitle(e.target.value);
  };

  const workFlowNotesHandler = (e) => {
    setWorkFlowNotes(e.target.value);
  };

  // start the work flow
  const startWorkFlow = () => {
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.userObject.userObj.token,
      },
      body: JSON.stringify({
        workFlowMasterId: workflowSelected,
        agencyCode: props.userObject.userObj.agencyCode,
        userId: selectedUser,
        notes: workFlowNotes,
        workFlowTitle: workFlowTitle,
      }),
    };
    //
    // get all the work flows for an agency
    //
    fetch(DefaultConfig + "workflow/workflow", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.log("error", error);
          return Promise.reject(error);
        }
        console.log(data);
      })
      .catch((error) => {
        alert("Not update error " + error);
      });

    alert("WorkFlow Started");
    setWorkFlowTitle("");
    setWorkFlowNotes("");
  };

  const WorkFlowMasters = () => {
    return (
      <select onChange={selectWorkFlowHandler} value={workflowSelected}>
        {workFlowMasterList.workFlowMaster.length > 0 &&
          workFlowMasterList.workFlowMaster.map((workFlow) => {
            return (
              <option key={workFlow._id} value={workFlow._id}>
                {workFlow.workFlowName}
              </option>
            );
          })}
      </select>
    );
  };
  //
  const AgentOptions = agencyUsers.users.map((agent) => {
    return (
      <option key={agent._id} value={agent._id}>
        {agent.username}
      </option>
    );
  });

  const AgentsDropDown = () => {
    return <select onChange={selectUserHandler}>{AgentOptions}</select>;
  };

  return (
    <div className="StartWorkFlow container">
      <div className="row">
        <h1>Start WorkFlow</h1>
      </div>
      <div className="row">
        <div className="col">
          <div className="row">
            <label>Pick a Work Flow</label>
          </div>
          <div className="row">
            <div>
              <WorkFlowMasters />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <label>Assign to:</label>
          </div>
          <AgentsDropDown />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="col">Work Flow Title</div>
          <div className="row">
            <div>
              <input
                type="text"
                placeholder="Title"
                onChange={workFlowTitleHandler}
                value={workFlowTitle}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="col">Work Flow Notes</div>
          <div className="row">
            <div>
              <input
                type="text"
                placeholder="Work Flow Notes"
                onChange={workFlowNotesHandler}
                value={workFlowNotes}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div>
          <button onClick={startWorkFlow}>Start WorkFlow</button>
        </div>
      </div>
    </div>
  );
};

export default StartWorkFlow;
