import "./Login.css";
import { useEffect, useState } from "react";
import DefaultConfig from "../../DefaultConfig";
import { useNavigate } from "react-router-dom";
const { Link } = require("react-router-dom");
const Login = (props) => {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // login handler
  const loginHandler = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: userName,
        password: password,
      }),
    };
    //
    // fethcing the login api
    //
    fetch(DefaultConfig + "auth/login", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.log("Login error", error);
          return Promise.reject(error);
        }
        console.log("Login success", data);
        props.login(data);
        navigate("/home");
      })
      .catch((error) => {
        alert("Not update error " + error);
      });
  };

  // username change handler
  const userNameChangeHandler = (e) => {
    setUsername(e.target.value);
  };
  // password change handler
  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="Login">
      <div className="container">
        <div className="screen">
          <div className="screen__content">
            <form className="login" onSubmit={loginHandler}>
              <div className="login__field">
                <i className="login__icon fas fa-user"></i>
                <input
                  type="text"
                  className="login__input"
                  placeholder="User name"
                  required
                  onChange={userNameChangeHandler}
                  value={userName}
                />
              </div>
              <div className="login__field">
                <i className="login__icon fas fa-lock"></i>
                <input
                  type="password"
                  className="login__input"
                  placeholder="Password"
                  required
                  onChange={passwordChangeHandler}
                  value={password}
                />
              </div>
              <Link to="/registration" style={{ padding: "10px" }}>
                Sign Up
              </Link>
              <button className="button login__submit">
                <span className="button__text">Log In</span>
                <i className="button__icon fas fa-chevron-right"></i>
              </button>
            </form>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
