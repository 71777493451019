import React, { useState, useEffect } from "react";
import DefaultConfig from "../DefaultConfig";
import "./Home.css";
import WorkFlowDetail from "./WorkFlowDetail/WorkFlowDetail";

const Home = (props) => {
  const [workFlows, setWorkFlows] = useState({ workFlows: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState({});

  // get the user work flows
  useEffect(() => {
    getUserWorkFlows();
  }, []);

  // set detail handler
  const openDetail = (workFlow) => {
    setSelectedWorkFlow(workFlow);
    setShowDetail(true);
  };

  //close detail
  const closeDetail = () => {
    setShowDetail(false);
  };

  // get the work flows for the user
  const getUserWorkFlows = () => {
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.userObject.userObj.token,
      },
      body: JSON.stringify({
        userId: props.userObject.userObj.userId,
      }),
    };
    //
    // get all the work flows for an user
    //
    fetch(DefaultConfig + "workflow/getUserWorkFlows", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.log("error", error);
          return Promise.reject(error);
        }
        setWorkFlows({ workFlows: data });
        setIsLoading(false);
      })
      .catch((error) => {
        alert("Not update error " + error);
        setIsLoading(false);
      });
  };

  // process next step
  const processNextStep = (id) => {
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.userObject.userObj.token,
      },
      body: JSON.stringify({
        workFlowId: id,
      }),
    };
    fetch(DefaultConfig + "workflow/nextstep", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.log("error", error);
          return Promise.reject(error);
        }
        getUserWorkFlows();
        console.log(data);
        setSelectedWorkFlow(data.workFlow);
      })
      .catch((error) => {
        alert("Not update error " + error);
      });
  };

  const Headers = () => {
    return (
      <div>
        <div className="row homeHeader">
          <div className="col">Worflow Title</div>
          <div className="col">Title</div>
          <div className="col">Current Step</div>
          <div className="col">Next Step</div>
          <div className="col">User Name</div>
          <div className="col">Start Date</div>
        </div>
      </div>
    );
  };

  // list of work flows on the home page
  const WorkFlowList = () => {
    return (
      <ul className="homeTable">
        <Headers />
        {workFlows.workFlows.map((workFlow) => (
          <div key={workFlow._id} className="row homeRow">
            <a className="col" onClick={() => openDetail(workFlow)}>
              {workFlow.workFlowName}
            </a>
            <div className="col">{workFlow.workFlowTitle}</div>
            <div className="col">{workFlow.currentStepName}</div>
            <div className="col">
              {workFlow.steps[Number(workFlow.currentStep) + 1].stepName}
              <div className="col">
                <button onClick={() => processNextStep(workFlow._id)}>
                  Next
                </button>
              </div>
            </div>
            <div className="col">{workFlow.userName}</div>
            <div className="col">
              {new Date(
                workFlow.steps[parseInt(workFlow.currentStep)].startDate
              ).toLocaleDateString()}
            </div>
          </div>
        ))}
      </ul>
    );
  };

  return (
    <div className="container">
      <h1>Home page</h1>
      {!showDetail && (
        <div className="row">
          {/* list the workflows */}
          {!isLoading && <WorkFlowList />}
          {isLoading && <div>loading...</div>}
        </div>
      )}
      {showDetail && (
        <div className="row">
          <WorkFlowDetail
            closeDetail={closeDetail}
            processNextStep={processNextStep}
            workFlow={selectedWorkFlow}
          />
        </div>
      )}
    </div>
  );
};
export default Home;
