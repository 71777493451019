const WorkFlowDetail = (props) => {
  console.log(props.workFlow);

  let currentStep = parseInt(props.workFlow.currentStep);

  return (
    <div>
      <h1>WorkFlowDetail</h1>
      <div className="container">
        <div>
          <div className="row">
            <div>
              <button className="btn btn-secondary" onClick={props.closeDetail}>
                Back
              </button>
            </div>
            <div className="col">{props.workFlow.workFlowName}</div>
            <div className="col">{props.workFlow.workFlowTitle}</div>
            <div className="col">{props.workFlow._id}</div>
          </div>
          <div className="row">
            <div className="col">Start Date</div>
            <div className="col">
              {new Date(
                props.workFlow.steps[currentStep].startDate
              ).toLocaleDateString()}
            </div>
            <div className="col" />
          </div>

          <div className="row">
            <div className="col">Current Step</div>
            <div className="col">{props.workFlow.currentStepName}</div>
            <div className="col" />
          </div>

          <div className="row">
            <div className="col">Next Step</div>
            <div className="col">
              {props.workFlow.steps[currentStep + 1].stepName}
            </div>
            <div className="col">
              <button
                className="btn btn-primary"
                onClick={() => props.processNextStep(props.workFlow._id)}
              >
                Next Step
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div>Steps</div>
            <div className="col">
              <ul>
                {props.workFlow.steps.map((step, index) => (
                  <li key={index}>
                    {step.stepName} - {step.startDate}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkFlowDetail;
