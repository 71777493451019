const { Link } = require("react-router-dom");
const Navbar = (props) => {
  let userRole = props.userObject.userObj.role;

  return (
    <nav style={{ textAlign: "center", marginTop: "20px" }}>
      <Link to="/home" style={{ padding: "10px" }}>
        Home
      </Link>
      {userRole === "admin" && (
        <Link to="/create-workflow" style={{ padding: "10px" }}>
          Create WorkFlow
        </Link>
      )}
      <Link to="/start-workflow" style={{ padding: "10px" }}>
        Start WorkFlow
      </Link>
      <button onClick={props.logOut}>Logout</button>
      <p>{props.userObject.userObj.username}</p>
      <p>{props.userObject.userObj.role}</p>
    </nav>
  );
};
export default Navbar;
