import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Navbar from "./layouts/NavBar";
import Home from "./Components/Home";
import About from "./Components/About";
import Protected from "./Components/Protected";
import Login from "./Components/Login/Login";
import Registration from "./Components/Registration/Registration";
import { useCookies, removeCookie } from "react-cookie";
import CreateWorkFlow from "./Components/CreateWorkFlow/CreateWorkFlow";
import StartWorkFlow from "./Components/StartWorkFlow/StartWorkFlow";

function App() {
  // set up the state
  const [isLoggedIn, setisLoggedIn] = useState(null);
  const [token, setToken] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [userObject, setUserObject] = useState(null);
  const navigate = useNavigate();

  // login function
  const logIn = (data) => {
    setisLoggedIn(true);
    setToken(token);
    setCookie("user", data);
    setUserObject(data);
  };

  // logout function and remove the cookie
  const logOut = () => {
    setisLoggedIn(false);
    removeCookie("user");
  };

  // check if the user is logged in
  useEffect(() => {
    if (cookies.user !== undefined && isLoggedIn === null) {
      logIn(cookies.user);
      setUserObject(cookies.user);
      navigate("/home");
    }
  }, []);

  // return jsx
  return (
    <div>
      <div>
        <Routes>
          {<Route path="/" element={<Login login={logIn} />} />}
          <Route
            path="/registration"
            element={<Registration login={logIn} />}
          />
          <Route
            path="/home"
            element={
              <Protected
                isLoggedIn={isLoggedIn}
                logOut={logOut}
                userObject={userObject}
              >
                <Home userObject={userObject} />
              </Protected>
            }
          />
          <Route
            path="/create-workflow"
            element={
              <Protected
                isLoggedIn={isLoggedIn}
                logOut={logOut}
                userObject={userObject}
              >
                <CreateWorkFlow userObject={userObject} />
              </Protected>
            }
          />
          <Route
            path="/start-workflow"
            element={
              <Protected
                isLoggedIn={isLoggedIn}
                logOut={logOut}
                userObject={userObject}
              >
                <StartWorkFlow userObject={userObject} />
              </Protected>
            }
          />
        </Routes>
      </div>
    </div>
  );
}
export default App;
