import "./Registration.css";
import { useEffect, useState } from "react";
import DefaultConfig from "../../DefaultConfig";
import { navigate, useNavigate } from "react-router-dom";
const { Link } = require("react-router-dom");
const Registration = (props) => {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [agencyCode, setAgencyCode] = useState("");
  const navigate = useNavigate();

  // Registration handler
  const RegistrationHandler = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: userName,
        password: password,
        email: email,
        agencyCode: agencyCode,
      }),
    };
    //
    // fethcing the login api
    //
    fetch(DefaultConfig + "auth/register", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.log("Login error", error);
          return Promise.reject(error);
        }
        console.log("Login success", data);
        props.login(data);
        navigate("/home");
      })
      .catch((error) => {
        alert("Not update error " + error);
      });
  };

  // username change handler
  const userNameChangeHandler = (e) => {
    setUsername(e.target.value);
  };
  // password change handler
  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const setAgencyCodeHandler = (e) => {
    setAgencyCode(e.target.value);
  };

  return (
    <div className="Registration">
      <div className="container">
        <div className="screen">
          <div className="screen__content">
            <form className="reg" onSubmit={RegistrationHandler}>
              <div className="login__field">
                <input
                  className="reg_input"
                  type="text"
                  placeholder="username"
                  required
                  onChange={userNameChangeHandler}
                  value={userName}
                />

                <div className="login__field">
                  <input
                    className="reg_input"
                    type="text"
                    placeholder="email"
                    required
                    onChange={emailChangeHandler}
                    value={email}
                  />
                </div>
                <div className="login__field">
                  <input
                    className="reg_input"
                    type="password"
                    placeholder="password"
                    required
                    onChange={passwordChangeHandler}
                    value={password}
                  />
                </div>
                <div className="login__field">
                  <input
                    className="reg_input"
                    type="text"
                    placeholder="agency code"
                    required
                    onChange={setAgencyCodeHandler}
                    value={agencyCode}
                  />
                </div>
                <Link to="/" style={{ padding: "10px" }}>
                  Login
                </Link>
                <div className="row">
                  <button className="button login__submit">Registration</button>
                </div>
              </div>
            </form>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
